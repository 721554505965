<!--  -->
<template>
<div class="top-header">
    <div class="top">
        <i class="el-icon-s-fold"></i>
        <div class="top-right">
            <i class="el-icon-search"></i>
            <i class="el-icon-bell"></i>
            <img src="" alt="">
            <span>momo.zxy</span>
        </div>
    </div>
    <div class="bottom">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/admin' }"
          >首页</el-breadcrumb-item
        >
        <el-breadcrumb-item :key="item" v-for="(item) in $route.meta">{{
          item
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
</div>
</template>

<script>
export default {
  data () {
    return {}
  },
  created () {},
  mounted () {}
}
</script>
<style scoped lang='less'>
.top-header {
    background: white;
    .top {
        height: 80px;
        width: calc(100% - 40px);
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .el-icon-search {
          margin-right: 10px;
        }
        .el-icon-bell {
          margin-right: 10px;
        }
    }
    .bottom {
        height: 60px;
        width: calc(100% - 40px);
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #efefef;
    }
}
</style>

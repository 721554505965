<!--  -->
<template>
  <div class="dashboard">
    <TopHeader />
    <div class="dashboard-main">
      <router-view />
    </div>
  </div>
</template>

<script>
import TopHeader from './TopHeader.vue'
export default {
  components: {
    TopHeader
  },
  data () {
    return {}
  },
  methods: {},
  created () {},
  mounted () {}
}
</script>
<style scoped lang='less'>
.dashboard {
  background: #efefef;
  max-height: 100vh;
  overflow: auto;
  .dashboard-main {
    min-height: calc(100vh - 170px);
    padding: 30px 30px 0 0;
  }
}
</style>
